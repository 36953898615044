import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { downloadBlob, generateInvoiceFileName } from '@/lib/download';
import {
  Invoice,
  InvoiceTextEmail,
  InvoiceStatus as InvoiceStatusTypes,
  SepaCollection,
  InvoiceLine,
} from '@/models';

import {
  default as InvoiceService,
  InvoiceSendType,
  InvoiceStatus,
} from '@/lib/services/invoices';
import { unwrapError } from '@/lib/helpers';
import { MatomoService } from '@/lib/services';
import { TrackingEvent } from '@/lib/trackingevent';
import { Location } from 'vue-router';
import { Getter } from 'vuex-class';

const MAX_DESCRIPTION_LENGTH = 80;

@Component
export default class ArchiveLine extends Vue {
  @Prop({ default: false })
  selected: boolean;

  @Prop({ default: null })
  invoice: Invoice;

  @Prop({ default: null })
  sepaCollection: SepaCollection;

  @Prop({ default: false })
  small: boolean;

  @Prop({ default: false })
  draft: boolean;

  @Getter('settings/isViewOnly') isViewOnly: boolean;

  public status: InvoiceStatus | null = null;
  isDownloading: boolean = false;

  showViewModal: boolean = false;
  showSendModal: boolean = false;
  showPackingSlipModal: boolean = false;
  showAddComment: boolean = false;

  select(): void {
    if (!this.invoice) return;
    this.$emit('select', this.invoice.id, !this.selected);
  }

  mounted(): void {
    this.fetchStatus();
  }

  get copyInvoiceRoute(): Location {
    return {
      name: 'invoice_create',
      query: { copy: this.invoice.id },
    };
  }

  get editInvoiceRoute(): Location {
    return {
      name: 'invoice_create',
      query: { edit: this.invoice.id },
    };
  }

  get customerRoute(): Location {
    return {
      name: 'customers',
      query: { select: String(this.invoice.receiver.id) },
    };
  }

  async downloadInvoice(): Promise<void> {
    this.isDownloading = true;
    const blob = await InvoiceService.view(this.invoice.id, true);
    downloadBlob(blob, generateInvoiceFileName(this.invoice));
    MatomoService.trackEvent(
      TrackingEvent.invoice().download(
        this.invoice.trackingType,
        this.invoice.id,
      ),
    );
    this.isDownloading = false;
  }

  async downloadInvoiceReminder(): Promise<void> {
    this.isDownloading = true;
    const blob = await InvoiceService.view(this.invoice.id, false);
    downloadBlob(blob, generateInvoiceFileName(this.invoice, '.pdf', true));
    MatomoService.trackEvent(
      TrackingEvent.invoice().download(
        this.invoice.trackingType,
        this.invoice.id,
      ),
    );
    this.isDownloading = false;
  }

  async downloadInvoiceZip(): Promise<void> {
    this.isDownloading = true;
    const blob = await InvoiceService.zip(this.invoice.id);
    downloadBlob(blob, generateInvoiceFileName(this.invoice, '.zip'));
    MatomoService.trackEvent(
      TrackingEvent.invoice().download(
        this.invoice.trackingType,
        this.invoice.id,
      ),
    );
    this.isDownloading = false;
  }

  async sendInvoice(
    type: string,
    invoice: Invoice,
    emailData: InvoiceTextEmail,
  ): Promise<void> {
    try {
      if (
        [InvoiceStatusTypes.DRAFT, InvoiceStatusTypes.DRAFT_QUOTATION].includes(
          invoice.status,
        )
      ) {
        await InvoiceService.send(invoice.id, InvoiceSendType.Mark);
      }

      if (type === 'email') {
        // Email it
        await InvoiceService.send(invoice.id, InvoiceSendType.Mail, {
          text: emailData,
        });
        MatomoService.trackEvent(
          TrackingEvent.invoice().email(invoice.trackingType, invoice.id),
        );
      } else if (type === 'sms') {
        await InvoiceService.send(invoice.id, InvoiceSendType.Sms);
        MatomoService.trackEvent(
          TrackingEvent.invoice().sms(invoice.trackingType, invoice.id),
        );
      } else {
        // Download it
        const response = await InvoiceService.sendDownloadPdf(invoice.id);
        await downloadBlob(response);
        MatomoService.trackEvent(
          TrackingEvent.invoice().download(invoice.trackingType, invoice.id),
        );
      }
      this.$emit('refresh');
      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
    }
  }

  async fetchStatus(): Promise<void> {
    if (!this.invoice) return;
    this.status = null;
    this.status = await InvoiceService.status(this.invoice.id);
  }

  addComment(): void {
    if (!this.invoice) return;
    this.showAddComment = true;
  }

  onCommentPanelClose(): void {
    this.showAddComment = false;
    if (this.status !== null) {
      this.status.comments.status = this.status.comments.comments.length > 0;
    }
  }

  onInvoiceModalClose(): void {
    this.showViewModal = false;
    if (this.status !== null) {
      this.status.comments.status = this.status.comments.comments.length > 0;
    }
  }

  get bookingClasses(): Record<string, boolean> {
    if (!this.status) return {};
    return {
      [this.status.booking.method]: true,
      'is-booked': this.status.booking.status,
    };
  }

  get checkboxId(): string {
    return `checkbox-${this['_uid']}`;
  }

  get sendDateFormatted(): string {
    if (this.invoice === null) return '';
    return new Date(
      Date.parse(this.invoice.meta.sendDate),
    ).toLocaleDateString();
  }

  get commentPanelIsVisible(): boolean {
    return !!(
      this.status &&
      this.status.comments &&
      (this.status.comments.status || this.showAddComment)
    );
  }

  get dueDateFormatted(): string {
    if (this.invoice === null) return '';
    return new Date(Date.parse(this.invoice.meta.dueDate)).toLocaleDateString();
  }

  get bookingMessage(): string {
    if (!this.status || !this.status.booking.message) return '';
    return this.status.booking.message.replace(/\/\//g, '<br>');
  }

  get description(): string {
    if (this.invoice === null) return '';
    const invoiceLine = this.invoice.lines.find(
      (e: InvoiceLine) => !e.realPrice.eq(0),
    );
    if (!invoiceLine) return '';
    const line = invoiceLine.description;
    if (line.length > MAX_DESCRIPTION_LENGTH) {
      return line.slice(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
    }
    return line;
  }
}
