import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { addDays, differenceInDays, format, parse } from 'date-fns';

import { InvoiceRecurring } from '.';

import { DATE_FORMAT_ISO } from '@/lib/constants';
import { Currency } from '@/lib/currency';
import { Language } from '@/plugins/i18n';

export class InvoiceMeta {
  /**
   * The internal date for this invoice
   *
   * @type {Date}
   */
  public date: Date = new Date();

  /**
   * The language that should be used to generate the invoice, as ISO 639-1
   *
   */
  @autoserializeAs('language') public language: string = Language.EN;

  /**
   * The payment term in days
   *
   * @type {number}
   */
  public term: number = 14;

  /**
   * The currency used, as ISO 4217
   *
   * @type {string}
   */
  @autoserializeAs('currency') public currency: Currency = Currency.EUR;

  /**
   * Amount of times we reminded the user of this invoice
   *
   * @type {number}
   */
  @autoserializeAs('remindCount') public remindCount: number = 0;

  /**
   * Tracking number linked to the invoice
   *
   * @type {string}
   */
  @autoserializeAs('trackingNumber') public trackingNumber: string = '';

  /**
   * Recurrence settings if the invoice is recurring. Otherwise ignored.
   *
   * @type {InvoiceRecurring | null}
   */
  @autoserializeAs('recurringSettings') public recurring: InvoiceRecurring;

  constructor() {
    this.recurring = new InvoiceRecurring(); // Optional but who cares
  }

  /**
   * Date the invoice is sent.
   *
   * @see OnSerialized
   * @see OnDeserialized
   * @returns {string} ISO string formatted date ("2018-12-31")
   */
  get sendDate(): string {
    return format(this.date, DATE_FORMAT_ISO);
  }

  set sendDate(value: string) {
    this.date = parse(value);
  }

  /**
   * Date the invoice is due.
   *
   * @see OnSerialized
   * @see OnDeserialized
   * @returns {string} ISO string formatted date ("2018-12-31")
   */
  get dueDate(): string {
    return format(addDays(this.sendDate, this.term), DATE_FORMAT_ISO);
  }

  static deserialize(json: Record<string, unknown>): InvoiceMeta {
    return Deserialize(json, InvoiceMeta);
  }

  public static OnSerialized(
    instance: InvoiceMeta,
    json: Record<string, unknown>,
  ): void {
    json.sendDate = instance.sendDate;
    json.dueDate = instance.dueDate;
  }

  public static OnDeserialized(
    instance: InvoiceMeta,
    json: Record<string, any>,
  ): void {
    instance.sendDate = format(json.sendDate, DATE_FORMAT_ISO);
    instance.term = differenceInDays(json.dueDate, json.sendDate);
    if (json.recurringSettings) {
      instance.recurring = InvoiceRecurring.deserialize(json.recurringSettings);
    }
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceMeta);
  }
}
