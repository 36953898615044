import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { InvoiceSender } from '@/models/invoice';
import { toUpper } from 'lodash-es';

export class Profile {
  @autoserializeAs('id') public id: number;
  @autoserializeAs('company_id') public companyId: number;

  @autoserializeAs('name') public name: string;
  @autoserializeAs('default') public isDefault: boolean;
  @autoserializeAs('disabled') public isDisabled: boolean;

  @autoserializeAs('expiration') public expiration: number;
  @autoserializeAs('language') public language: string;
  @autoserializeAs('logo') public logo: string | null;
  public renderedLogo: string | null = null;

  @autoserializeAs('extratext') public extraText: string;
  @autoserializeAs('extratextbottom') public extraTextBottom: string;
  @autoserializeAs('footer') public footer: string;
  @autoserializeAs('quotationfooter') public footerQuotation: string;

  // @autoserializeAs('emailtext') public emailText: string;
  @autoserializeAs('emailtext') public emailTextId: number;
  // @autoserializeAs('emailtextreminder') public emailTextReminder: string;
  @autoserializeAs('emailtextreminder') public emailTextReminderId: number;
  // @autoserializeAs('emailtextquotation') public emailTextQuotation: string;
  @autoserializeAs('emailtextquotation') public emailTextQuotationId: number;
  @autoserializeAs('merge_mail_attachments')
  public merge_mail_attachments: boolean;

  @autoserializeAs('companyname') public lineCompanyName: string;
  @autoserializeAs('contact') public lineContact: string;
  @autoserializeAs('address') public lineAddress: string;
  @autoserializeAs('postalcode') public linePostalcode: string;
  @autoserializeAs('city') public lineCity: string;
  @autoserializeAs('countryline') public lineCountry: string;
  @autoserializeAs('vatnumber') public lineVATNumber: string;
  @autoserializeAs('coc') public lineCoCNumber: string;
  @autoserializeAs('iban') public lineIBAN: string;
  @autoserializeAs('phone') public linePhoneNumber: string;
  @autoserializeAs('email') public lineEmail: string;
  @autoserializeAs('extra') public lineExtra: string;

  @autoserializeAs('emailfrom') public fromEmailId: number;
  @autoserializeAs('emailcc') public ccEmailId: number;

  @autoserializeAs('smstext') public smsTextId: number;

  @autoserializeAs('logo_position') public logoPosition: string | null;
  @autoserializeAs('sender_position') public senderPosition: string | null;
  @autoserializeAs('margin_global') public marginGlobal: number | null;
  @autoserializeAs('margin_header') public marginHeader: number | null;

  get lines(): string[] {
    return [
      this.lineCompanyName,
      this.lineContact,
      this.lineAddress,
      this.linePostalcode,
      this.lineCity,
      this.lineCountry,
      this.lineVATNumber,
      this.lineCoCNumber,
      this.lineIBAN,
      this.linePhoneNumber,
      this.lineEmail,
      this.lineExtra,
    ];
  }

  set lines(data: string[]) {
    // There is no (easy) way to calculate this number without keeping a list of fields
    // But that list of fields would be mostly useless with the destructuring syntax
    // :(
    if (data.length !== 12) {
      throw new Error('Invalid line count when multi-setting profile lines');
    }
    [
      this.lineCompanyName,
      this.lineContact,
      this.lineAddress,
      this.linePostalcode,
      this.lineCity,
      this.lineCountry,
      this.lineVATNumber,
      this.lineCoCNumber,
      this.lineIBAN,
      this.linePhoneNumber,
      this.lineEmail,
      this.lineExtra,
    ] = data;
  }

  public toInvoiceSender(): InvoiceSender {
    return new InvoiceSender(
      this.lineCompanyName,
      this.lineAddress,
      this.linePostalcode,
      this.lineCity,
      this.lineCountry,
      this.lineCompanyName,
      `${this.id}`,
    );
  }

  static deserialize(json: Record<string, unknown>): Profile {
    return Deserialize(json, Profile);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Profile);
  }

  public static OnDeserialized(
    instance: Profile,
    json: Record<string, any>,
  ): void {
    void json; // void json to silence 'unused variable'
    // Coerce php 'booleans' into actual booleans
    instance.isDefault = !!+instance.isDefault;
    instance.isDisabled = !!+instance.isDisabled;
    if (instance.lineCountry.length === 2) {
      instance.lineCountry = toUpper(instance.lineCountry);
    }
  }
}
