






































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import InvoiceModalSidebar from '@/components/InvoiceModalSidebar.vue';
import { InvoiceService } from '@/lib/services';

@Component({
  components: {
    InvoiceModalSidebar,
  },
})
export default class PackingSlipModal extends Vue {
  @Prop()
  readonly invoiceId!: string;

  @Prop()
  readonly trackingNumber!: string;

  reactiveTrackingNumber: string = '';
  loading: boolean = false;

  async mounted(): Promise<void> {
    if (!this.invoiceId) return;
    this.reactiveTrackingNumber = this.trackingNumber;
  }

  async previewPackingSlip(): Promise<void> {
    if (this.reactiveTrackingNumber.length) {
      await InvoiceService.storeTrackingNumber(
        this.invoiceId,
        this.reactiveTrackingNumber,
      );
    }
    const blob = await InvoiceService.generatePackingSlip(this.invoiceId);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
